import {
    doc,
    getDoc,
    setDoc,
    addDoc,
    collection as firestoreCollection,
    getDocs,
    writeBatch,
    deleteDoc,
    query,
    where,
    orderBy,
    WhereFilterOp,
    FieldPath,
    updateDoc,
    DocumentReference,
    DocumentData,
    OrderByDirection,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadString, StringFormat, UploadMetadata } from 'firebase/storage';
import { db, functions } from '@/plugins/firebase';
import { FirebaseDocumentData } from '@/types';
import { store } from '@/plugins/store';

export enum CallableFunctions {
    GRANT_ROLE = 'grantRole',
    AddUserToCustomer = 'addUserToCustomer',
    CustomerStatistics = 'customerStatistics',
    CustomerStatisticsAll = 'customerStatisticsAll',
    getSignedURLs = 'getSignedURLs',
}

export enum Collection {
    Users = 'users',
    Customers = 'customers',
    Teams = 'teams',
}

export enum MetaDataDocuments { }

export default class FirebaseClient {
    public static getAuthToken() {
        return getAuth().currentUser?.getIdToken();
    }

    public static getDocumentReference(collection: Collection, ...pathSegments: string[]) {
        return doc(db, collection, ...pathSegments);
    }

    public static getDocumentAddReference(collection: Collection, ...pathSegments: string[]) {
        return doc(firestoreCollection(db, collection, ...pathSegments));
    }

    public static async getDocumentData(collection: Collection, id: string) {
        const documentReference = doc(db, collection, id);
        const documentSnapshot = await getDoc(documentReference);
        return { ...documentSnapshot.data(), id };
    }

    public static async getCollection(rootCollection: Collection, ...pathSegments: string[]): Promise<({ [key: string]: any } & FirebaseDocumentData)[]> {
        const querySnapshot = await getDocs(firestoreCollection(db, rootCollection, ...pathSegments));
        return querySnapshot.docs.map((currentDoc) => {
            return { ...currentDoc.data(), id: currentDoc.id };
        });
    }

    public static async getQuery(
        rootCollection: string,
        constraints: { property: string | FieldPath; opStr: WhereFilterOp; value: any }[],
        orders?: { property: string | FieldPath; direction: string }[]
    ): Promise<({ [key: string]: any } & FirebaseDocumentData)[]> {
        const whereClauses = constraints.map((currentConstraint) => where(currentConstraint.property, currentConstraint.opStr, currentConstraint.value));
        const orderClauses = (orders || []).map((currentConstraint) => orderBy(currentConstraint.property, currentConstraint.direction as OrderByDirection));
        const querySnapshot = await getDocs(query(firestoreCollection(db, rootCollection), ...whereClauses, ...orderClauses));
        return querySnapshot.docs.map((currentDoc) => {
            return { ...currentDoc.data(), id: currentDoc.id };
        });
    }

    /* Firestore only allows up to 10 'in' constraints. This function separates an arbitrary number of 'in' constraints into batches and return the result of all of them */
    public static async getBatchedQuery(rootCollection: Collection, constraint: { property: string | FieldPath; opStr: 'in'; value: any[] }): Promise<({ [key: string]: any } & FirebaseDocumentData)[]> {
        const ids = [...constraint.value];

        const batches = [];
        while (ids.length) {
            const batch = ids.splice(0, 10);
            batches.push(
                getDocs(query(firestoreCollection(db, rootCollection), where(constraint.property, constraint.opStr, batch))).then((batchResult) =>
                    batchResult.docs.map((currentDoc) => {
                        return { ...currentDoc.data(), id: currentDoc.id };
                    })
                )
            );
        }

        return (await Promise.all(batches)).flat();
    }

    public static async setDocument(collection: string, id: string, data: any, merge: boolean) {
        const user = store.state.user;
        data.updatedBy = user?.id || 'unknown';
        if (id === '') {
            const documentReference = firestoreCollection(db, collection);
            return addDoc(documentReference, data);
        }
        const documentReference = doc(db, collection, id);
        return setDoc(documentReference, data, { merge });
    }

    public static async updateDoc(collection: string, id: string, data: any) {
        const user = store.state.user;
        data.updatedBy = user?.id || 'unknown';
        const documentReference = doc(db, collection, id);
        await updateDoc(documentReference, data);
    }

    public static async deleteDocument(collection: string, id: string) {
        await deleteDoc(doc(db, collection, id));
    }

    public static async callFunction(callable: CallableFunctions, params?: any) {
        const call = httpsCallable(functions, callable);
        const result = await call(params);
        return result.data as any;
    }

    public static async uploadString(path: string, data: string, format: StringFormat = 'raw', contentType: UploadMetadata = { contentType: 'application/json' }) {
        await uploadString(ref(getStorage(), path), data, format, contentType);
        return path;
    }

    public static async batchWrite(values: { docRef: DocumentReference<DocumentData>; value: Object }[], callback?: (size: number) => any) {
        const batch = writeBatch(db);
        const user = store.state.user;
        values.forEach((item) => batch.set(item.docRef, { ...item.value, updatedBy: user?.id || 'unknown' }));
        return batch.commit().then(() => {
            if (callback !== undefined) callback(values.length);
        });
    }
}
