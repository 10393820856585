
import { defineComponent } from 'vue';

export default defineComponent({
    emits: ['action-clicked'],
    props: {
        params: {
            type: Object as () => { value: any; clicked: any },
            required: true,
        },
    },
    methods: {
        clickHandler(event: any) {
            if (this.params.clicked) {
                this.params.clicked(event);
            }
        },
    },
});
