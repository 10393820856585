import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createBlock(_component_Tabs, {
    activeTab: _ctx.activeTab,
    "onUpdate:activeTab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
    tabs: _ctx.tabs
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeTab.component), {
        customer: _ctx.customer,
        onImport_done: _ctx.handleImport
      }, null, 8, ["customer", "onImport_done"]))
    ]),
    _: 1
  }, 8, ["activeTab", "tabs"]))
}