
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/ui/Modal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { Customer } from '@/types';
import { ActionType, store } from '@/plugins/store';
import ConfirmDeleteModal from '@/views/dashboard/customers/customer/ConfirmDeleteModal.vue';

export default defineComponent({
    emits: [],
    components: { Modal, CustomButton, CustomInput, Spinner, ConfirmDeleteModal },
    props: {
        customer: {
            type: Object as () => Customer,
            required: true,
        },
    },
    data() {
        const { t } = useI18n();
        return {
            toast: useToast(),
            editingCustomer: false,
            form: {
                name: (this.$props.customer as Customer)?.name || '',
            },
            users: Object.entries((this.$props.customer as Customer)?.roles || {}).map((role: any) => {
                return { id: role[0], name: role[1].displayName, role: role[1].role };
            }),
            t,
        };
    },

    methods: {
        async onSubmitClicked(close: CallableFunction) {
            if (this.form.name.length < 3) {
                this.toast.error(this.t('Customer_Customer_editCustomer_errorLength'));
                return;
            }
            try {
                this.editingCustomer = true;
                await store.dispatch(ActionType.EditCustomer, { form: { id: this.$props.customer.id as string, name: this.form.name } });
                this.editingCustomer = false;
                this.toast.success(this.t('Customer_Customer_editCustomer_updateSuccess'));
                close();
            } catch (error: any) {
                this.toast.error(this.t('Customer_Customer_editCustomer_errorGenerel'));
            }
        },
    },
});
