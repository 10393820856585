import { ColDef } from 'ag-grid-community';
import { useI18n } from 'vue-i18n';
import { computed, unref } from 'vue';
import { useRouter } from 'vue-router';
import { Video } from '@/types';

export default function useVideosTableSettings(Videos: any) {
    const { t } = useI18n();
    const router = useRouter();
    const ROW_HEIGHT = 92;
    const headers = [
        { headerName: t('video_name'), field: 'name', sortable: true },
        { headerName: '', field: 'action', sortable: false, width: 16, headerClass: '!bg-white', cellClass: '!p-0', cellRendererFramework: 'ActionCellRenderer' },
    ] as ColDef[];

    const onItemClicked = (item: any) => {
        router.push({ name: 'Video', params: { customerId: item.customerId, videoId: item.id } });
    };

    const styledVideos = computed(() => {
        return (
            unref(Videos).map((video: Video) => {
                return {
                    ...video,
                };
            }) || []
        );
    });

    return { ROW_HEIGHT, headers, onItemClicked, styledVideos };
}
