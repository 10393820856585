
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { ActionType, store } from '@/plugins/store';
import { Playlist } from '@/types';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';
import { useRouter,useRoute } from 'vue-router';

export default defineComponent({
    components: { ConfirmationModal },
    props: {
        form: {
            type: Object as () => {
                playlist: Playlist;
                name: string;
                id: string;
            },
            required: true,
        },
    },
    data() {
        const { t } = useI18n();
        return {
            route:useRoute(),
            router:useRouter(),
            toast: useToast(),
            modalMessage: t('deletePlaylist_confirmMsg', this.form.name),
            t,
        };
    },
    methods: {
        onSubmit(close: CallableFunction): any {
            store
                .dispatch(ActionType.DeletePlaylist, { form: { playlist: this.$props.form.playlist,customerId:this.route.params.customerId as string } })
                .then(() => {
                    this.toast.success(this.t('deletePlaylist_success'));
                    this.router.push({name:'playlists',params:{id:this.route.params.customerId}});
                })
                .catch(() => {
                    this.toast.error(this.t('deletePlaylist_errorGeneral'));
                });
            close();
        },
    },
});
