import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "relative h-12 w-full bg-white" }
const _hoisted_2 = { class: "relative mt-1" }
const _hoisted_3 = { class: "block truncate" }
const _hoisted_4 = { class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2" }
const _hoisted_5 = {
  key: 0,
  class: "absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectorIcon = _resolveComponent("SelectorIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Listbox, {
      modelValue: _ctx.selection,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selection) = $event)),
        _ctx.changedSelection
      ]
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ListboxButton, { class: "relative w-full border border-gray-300 opacity-80 cursor-default rounded-lg bg-white -top-1 h-[3.2rem] py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300" }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.selection.name), 1),
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_SelectorIcon, {
                  class: "h-5 w-5 text-gray-400",
                  "aria-hidden": "true"
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_Transition, {
            "leave-active-class": "transition duration-100 ease-in",
            "leave-from-class": "opacity-100",
            "leave-to-class": "opacity-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ListboxOptions, { class: "block absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (option) => {
                    return (_openBlock(), _createBlock(_component_ListboxOption, {
                      key: option.name,
                      value: option.value,
                      as: "template"
                    }, {
                      default: _withCtx(({ active, selected }) => [
                        _createElementVNode("li", {
                          class: _normalizeClass([active ? 'bg-amber-100 text-amber-900' : 'text-gray-900', 'z-10 relative cursor-default select-none py-2 pl-10 pr-4'])
                        }, [
                          _createElementVNode("span", {
                            class: _normalizeClass([selected ? 'font-medium' : 'font-normal', 'block truncate'])
                          }, _toDisplayString(option.name), 3),
                          selected
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                _createVNode(_component_CheckIcon, {
                                  class: "h-5 w-5",
                                  "aria-hidden": "true"
                                })
                              ]))
                            : _createCommentVNode("", true)
                        ], 2)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onUpdate:modelValue"])
  ]))
}