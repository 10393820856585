import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex h-full flex-col" }
const _hoisted_2 = { class: "text-center pt-2" }
const _hoisted_3 = { class: "flex h-full flex-col my-auto" }
const _hoisted_4 = { class: "flex space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    size: "w-175 h-fit",
    padding: "py-10 px-28"
  }, {
    toggle: _withCtx((state) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(state)))
    ]),
    body: _withCtx((state) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.t('upload_new_thumbnail_header')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("input", {
            type: "file",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fileChanged && _ctx.fileChanged(...args)))
          }, null, 32)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_CustomButton, {
            class: "px-2 mt-auto mx-auto w-48",
            colors: "bg-gray-400 text-white",
            onClick: state.close
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('cancle_btn')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_CustomButton, {
            class: "px-2 mt-auto mx-auto w-48",
            colors: "bg-green-400",
            onClick: ($event: any) => (_ctx.onSubmit(state.close))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('upload_new_thumbnail_btn')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ])
    ]),
    _: 3
  }))
}