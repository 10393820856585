import { ColDef } from 'ag-grid-community';
import { useI18n } from 'vue-i18n';
import { computed, unref } from 'vue';
import { useRouter } from 'vue-router';
import { Customer } from '@/types';
import { store } from '@/plugins/store';

export default function useCustomersTableSettings(customers: any) {
    const { t } = useI18n();
    const router = useRouter();
    const ROW_HEIGHT = 92;
    const headers = [
        { headerName: t('composable_Customers_name'), field: 'name', sortable: true },
        { headerName: t('composable_Customers_id'), field: 'id', sortable: false },
        { headerName: '', field: 'action', sortable: false, width: 16, headerClass: '!bg-white', cellClass: '!p-0', cellRendererFramework: 'ActionCellRenderer' },
    ] as ColDef[];

    const onItemClicked = (item: Customer) => {
        const user = store.state.user;
        if (user !== null && (user.customers as Customer[]).find((cust) => cust.id === item.id) === undefined) (user.customers as Customer[]).push(item);
        router.push({ name: 'Customer', params: { id: item.id } });
    };

    const styledCustomers = computed(() => {
        const user = store.state.user;
        return (
            unref(customers)
                .filter((customer: Customer) => user?.roles.includes('superadmin') || (customer.apiKey && ['editor', 'owner'].includes(customer.roles[user?.id ?? '-'].role)))
                .map((customer: Customer) => {
                    return {
                        ...customer,
                    };
                }) || []
        );
    });

    return { ROW_HEIGHT, headers, onItemClicked, styledCustomers };
}
