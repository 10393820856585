
import { defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Customer } from '@/types';
import CustomButton from '@/components/ui/CustomButton.vue';
import Card from '@/components/common/layouts/Card.vue';
import InfoTable, { InfoTableItem } from '@/components/common/tables/InfoTable.vue';
import EditCustomerModal from '@/views/dashboard/customers/customer/EditCustomerModal.vue';
import AddUserModal from '@/views/dashboard/customers/customer/AddUserModal.vue';
import { store } from '@/plugins/store';
import languages from '@/plugins/languages';

export default defineComponent({
    emits: ['refetch-user'],
    components: { CustomButton, Card, InfoTable, EditCustomerModal, AddUserModal },
    props: {
        customer: {
            type: Object as () => Customer,
            required: true,
        },
    },
    setup() {
        const { t } = useI18n();
        return { t, user: computed(() => store.state.user) };
    },
    computed: {
        infoTableItems(): InfoTableItem[] {
            return [
                { id: 'name', name: this.t('Customers_Customer_editName'), value: this.$props.customer?.name || '' },
                { id: 'id', name: this.t('Customers_Customer_editId'), value: this.$props.customer?.id || '' },
                { id: 'roles', name: `${this.t('Customers_Customer_editRoles')}:`, value: '' },
            ].concat(
                Object.values(this.$props.customer?.roles || {}).map((role: any) => {
                    return { id: role.displayName, name: role.displayName, value: role.role };
                })
            );
        },
    },
    methods: {
        onEditClicked() {
            alert('Edit clicked');
        },
    },
});
