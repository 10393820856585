import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute right-64 my-auto bottom-4" }
const _hoisted_2 = {
  key: 0,
  class: "absolute right-10 my-auto bottom-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardContentHeader = _resolveComponent("DashboardContentHeader")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_ConfirmDeleteModal = _resolveComponent("ConfirmDeleteModal")!
  const _component_UpdateThumbnailModal = _resolveComponent("UpdateThumbnailModal")!
  const _component_InfoTable = _resolveComponent("InfoTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DashboardContentHeader, {
      "icon-source": "/icons/ic_users.svg",
      header: _ctx.video !== null ? _ctx.video.name : _ctx.$t('video_name_header')
    }, null, 8, ["header"]),
    (_ctx.video !== null)
      ? (_openBlock(), _createBlock(_component_Card, {
          key: 0,
          class: "col-span-2",
          header: _ctx.$t('video_header')
        }, {
          "header-action": _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ConfirmDeleteModal, {
                form: { video: _ctx.video, name: _ctx.video.name, id: _ctx.video.id }
              }, {
                default: _withCtx(({ open }) => [
                  _createVNode(_component_CustomButton, {
                    class: "px-2 mt-auto mx-auto w-48",
                    colors: "text-white bg-red-700",
                    onClick: open
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('video_delete_btn')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              }, 8, ["form"])
            ]),
            (_ctx.customer !== null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_UpdateThumbnailModal, {
                    customer: _ctx.customer,
                    form: { video: _ctx.video, name: _ctx.video.name, id: _ctx.video.id }
                  }, {
                    default: _withCtx(({ open }) => [
                      _createVNode(_component_CustomButton, {
                        class: "px-2 mt-auto mx-auto w-48",
                        colors: "text-white bg-green-700",
                        onClick: open
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('video_update_thumb_btn')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 1
                  }, 8, ["customer", "form"])
                ]))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_InfoTable, { items: _ctx.infoTableItems }, null, 8, ["items"])
          ]),
          _: 1
        }, 8, ["header"]))
      : _createCommentVNode("", true)
  ]))
}