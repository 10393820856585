
import { defineComponent, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { ActionType, store } from '@/plugins/store';
import { Playlist } from '@/types';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';

export default defineComponent({
    components: { ConfirmationModal, CustomButton },
    props: {
        form: {
            type: Object as () => {
                playlist: Playlist;
                name: string;
                id: string;
            },
            required: true,
        },
    },
    data() {
        const { t } = useI18n();
        return {
            file: ref(null) as any,
            route: useRoute(),
            router: useRouter(),
            toast: useToast(),
            modalMessage: t('playlistThumbnail_confirmMsg', this.form.name),
            t,
        };
    },
    methods: {
        fileChanged(e: any) {
            this.file = (e.target.files || e.dataTransfer.files)?.[0] ?? null;
        },
        onSubmit(close: CallableFunction): any {
            store
                .dispatch(ActionType.UpdatePlaylistThumbnail, { form: { playlist: this.$props.form.playlist, customerId: this.route.params.customerId as string, file: this.file } })
                .then(() => {
                    this.toast.success(this.t('playlistThumbnail_success'));
                })
                .catch(() => {
                    this.toast.error(this.t('playlistThumbnail_errorGeneral'));
                });
            close();
        },
    },
});
