
import { defineComponent, computed, ComputedRef, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import dataURItoBlob from '@uppy/utils/lib/dataURItoBlob';
import { Customer } from '@/types';
import DashboardContentHeader from '@/components/common/DashboardContentHeader.vue';
import GenericTable from '@/components/common/tables/genericTable/GenericTable.vue';
import InfoTable, { InfoTableItem } from '@/components/common/tables/InfoTable.vue';
import { store, ActionType } from '@/plugins/store';

export default defineComponent({
    components: { DashboardContentHeader, GenericTable, Chart, InfoTable },
    setup() {
        const route = useRoute();
        const routeId = route.params.id as string;
        const customer = computed(() => {
            const filtered = computed(() => (computed(() => (store.state.user !== null ? store.state.user.customers : [])).value as Array<Customer>).filter((customer) => customer.id === routeId));
            if (filtered.value.length > 0) {
            } else if (store.state.user) store.dispatch(ActionType.GetCustomer, { form: { id: routeId } });
            return filtered.value.length > 0 ? filtered.value[0] : null;
        });
        const statistics: any = computed(() => customer.value?.statistics ?? {});
        const egressData: ComputedRef<{ 0: Date; 1: number }[]> = computed(() =>
            Object.entries(statistics.value?.BandwidthUsedChart ?? {}).map(([key, value]) => [new Date(key), Math.round((value as number) / 10000000) / 100])
        );
        const egressDataCached: ComputedRef<{ 0: Date; 1: number }[]> = computed(() =>
            Object.entries(statistics.value?.BandwidthCachedChart ?? {}).map(([key, value]) => [new Date(key), Math.round((value as number) / 10000000) / 100])
        );

        watch(egressData, (newVal) => {
            chart.series[0].setData(newVal);
        });
        let chart: any;

        const { t } = useI18n();
        return {
            t,
            statistics,
            customer,
            embeded: computed(() => route.name !== 'Statistics'),
            chartOptions: {
                title: {
                    text: 'Data usage in GB',
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        formatter(input: any) {
                            return Highcharts.dateFormat('%d/%m/%Y', egressData.value[input.pos as number][0].getTime());
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: 'Data usage',
                    },
                    min: 0,
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: true,
                            radius: 2.5,
                        },
                    },
                },
                series: [
                    {
                        name: 'Egress',
                        data: egressData.value,
                    },
                    {
                        name: 'Egress Cached',
                        data: egressDataCached.value,
                    },
                ],
            },
            chartCallback: (newChart: any) => {
                chart = newChart;
            },
            getPeriodFromStatistics() {
                if (statistics.value?.BandwidthUsedChart) {
                    const values = Object.keys(statistics.value.BandwidthUsedChart);
                    const convertDate = (date: string) => date.split('T')[0].split('-').reverse().join('/');
                    return `${convertDate(values[0] as string)} - ${convertDate(values[values.length - 1] as string)}`;
                }
                return '';
            },
        };
    },
    computed: {
        infoTableItems(): InfoTableItem[] {
            return [
                { id: 'period', name: this.t('period'), value: this.getPeriodFromStatistics() },
                { id: 'total_bandwidth', name: this.t('total_bandwidth'), value: `${Math.round(this.statistics.TotalBandwidthUsed / 10000000) / 100} GB` },
            ];
        },
    },
    created() {
        const route = useRoute();
        store.dispatch(ActionType.GetCustomerStatistics, { form: { customerId: route.params.id as string } });
    },
});
