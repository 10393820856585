import { RouteRecordRaw } from 'vue-router';
import Dashboard from '@/views/dashboard/Index.vue';
import Auth from '@/views/auth/Index.vue';
import Login from '@/views/auth/Login.vue';
import Signup from '@/views/auth/Signup.vue';
import Users from '@/views/dashboard/users/Index.vue';
import User from '@/views/dashboard/users/user/Index.vue';
import Customers from '@/views/dashboard/customers/Index.vue';
import Customer from '@/views/dashboard/customers/customer/Index.vue';
import Videos from '@/views/dashboard/customers/customer/videos/Index.vue';
import Video from '@/views/dashboard/customers/customer/videos/video/Index.vue';
import Playlists from '@/views/dashboard/customers/customer/playlists/Index.vue';
import Playlist from '@/views/dashboard/customers/customer/playlists/playlist/Index.vue';
import CustomersAll from '@/views/admin/customers/Index.vue';
import StatusBoard from '@/views/dashboard/statusboard/Index.vue';
import Admin from '@/views/admin/Index.vue';

import { store } from '@/plugins/store';
import { computed } from 'vue';

const user = computed(() => store.state.user);

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'Auth',
        component: Auth,
        redirect: '/auth/login',
        children: [
            {
                path: '/auth/login',
                name: 'Login',
                component: Login,
                meta: { skipAuthCheck: true },
            },
        ],
    },
    {
        path: '/signup',
        name: 'signup',
        component: Auth,
        redirect: '/auth/signup',
        children: [
            {
                path: '/auth/signup',
                name: 'Signup',
                component: Signup,
                meta: { skipAuthCheck: true },
            },
        ],
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        redirect: '/dashboard',
        meta: {
            isDashboardRoute: true,
        },
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: StatusBoard,
                meta: {
                    appearsInSidebar: false,
                    sidebarText: 'router_sidebar_dashboard',
                    sidebarIcon: '/icons/nav_users_active.svg',
                },
            },
            {
                path: '/users',
                name: 'Users',
                component: Users,
                meta: {
                    requireRole: ['superadmin', 'allCustomers'],
                    appearsInSidebar: true,
                    sidebarText: 'router_sidebar_users',
                    sidebarIcon: '/icons/nav_users_active.svg',
                },
            },
            {
                path: '/users/:id',
                name: 'User',
                component: User,
                meta: {
                    requireRole: ['superadmin', 'allCustomers'],
                    appearsInSidebar: false,
                },
            },
            {
                path: '/users/create',
                name: 'users_create',
                component: CustomersAll,
                meta: {
                    requireRole: ['allCustomers', 'superadmin'],
                    appearsInSidebar: false,
                    sidebarText: 'router_sidebar_createUser',
                    sidebarIcon: '/icons/nav_users_active.svg',
                },
            },
            {
                path: '/Customer',
                name: 'Customers',
                component: Customers,
                meta: {
                    appearsInSidebar: computed(() => (user.value?.customers?.length && user.value?.customers?.length > 1)),
                    sidebarText: 'router_sidebar_Customers',
                    sidebarIcon: '/icons/nav_users_active.svg',
                },
            },
            {
                path: '/Customer/:id/edit',
                name: 'Customer',
                component: Customer,
                meta: {
                    appearsInSidebar: false,
                },
            },
            {
                path: '/Customer/:id',
                name: 'Videos',
                component: Videos,
                meta: {
                    appearsInSidebar: true,
                    requiresUserId: true,
                    sidebarText: 'router_sidebar_videos',
                    sidebarIcon: '/icons/nav_users_active.svg',
                },
            },
            {
                path: '/Customer/:customerId/video/:videoId',
                name: 'Video',
                component: Video,
                meta: {
                },
            },
            {
                path: '/Customer/:id/playlists',
                name: 'Playlists',
                component: Playlists,
                meta: {
                    appearsInSidebar: true,
                    requiresUserId: true,
                    sidebarText: 'router_sidebar_playlists',
                    sidebarIcon: '/icons/nav_users_active.svg',
                },
            },
            {
                path: '/Customer/:customerId/playlists/:playlistId',
                name: 'Playlist',
                component: Playlist,
                meta: {
                },
            },
            {
                path: '/all/Customers',
                name: 'All_Customers',
                component: CustomersAll,
                meta: {
                    requireRole: ['allCustomers', 'superadmin'],
                    appearsInSidebar: true,
                    sidebarText: 'router_sidebar_allCustomers',
                    sidebarIcon: '/icons/nav_users_active.svg',
                },
            },
            {
                path: '/Admin',
                name: 'admin',
                component: Admin,
                meta: {
                    requireRole: ['superadmin'],
                    appearsInSidebar: true,
                    sidebarText: 'router_sidebar_admin',
                    sidebarIcon: '/icons/nav_users_active.svg',
                },
            },
        ],
    },
];
