
import { defineComponent, computed } from 'vue';
import DashboardContentHeader from '@/components/common/DashboardContentHeader.vue';
import { store } from '@/plugins/store';
import { useRouter } from 'vue-router';
import Card from '@/components/common/layouts/Card.vue';
import InfoTable from '@/components/common/tables/InfoTable.vue';

export default defineComponent({
    components: { DashboardContentHeader, Card, InfoTable },
    setup() {
        return {
            router: useRouter(),
            user: computed(() => store.state.user)
        };
    },
});
