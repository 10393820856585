
import { Component, defineComponent } from 'vue';

export type Tab = {
    name: string;
    component: Component;
};

export default defineComponent({
    emits: ['update:activeTab'],
    props: {
        tabs: {
            type: Array as () => Tab[],
            default: [],
        },
        activeTab: {
            type: Object as () => null | Tab,
            default: null,
        },
    },
    computed: {
        computedActiveTab: {
            get(): Tab | null {
                return this.activeTab;
            },
            set(newValue: Tab) {
                this.$emit('update:activeTab', newValue);
            },
        },
    },
});
