
import { defineComponent, shallowRef, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Customer } from '@/types';
import Tabs, { Tab } from '@/components/common/layouts/Tabs.vue';
import CustomerEdit from '@/views/dashboard/customers/customer/CustomerEdit.vue';
import Playlists from '@/views/dashboard/customers/customer/playlists/Index.vue';
import Videos from '@/views/dashboard/customers/customer/videos/Index.vue';
import Statistics from '@/views/dashboard/statistics/Index.vue';
import { store } from '@/plugins/store';

export default defineComponent({
    components: { Tabs },
    props: {
        customer: {
            type: Object as () => Customer,
            required: true,
        },
    },
    data() {
        const { t } = useI18n();
        let tabs: any = ref([
            { name: t('Customer_Customer_tab_edit'), component: shallowRef(CustomerEdit) },
            { name: t('Customer_Customer_tab_playlists'), component: shallowRef(Playlists) },
            { name: t('Customer_Customer_tab_videos'), component: shallowRef(Videos) },
            { name: t('Customer_Customer_tab_statistics'), component: shallowRef(Statistics) },
        ]);
        const user = computed(() => store.state.user);
        return {
            activeTab: null as null | Tab,
            tabs: tabs as Tab[],
        };
    },
    created() {
        this.activeTab = this.tabs[0];
    },
    methods: {
        handleImport() {
            this.activeTab = this.tabs[1];
        },
    },
});
