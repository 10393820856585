
import { defineComponent } from 'vue';
import Modal from '@/components/ui/Modal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';

export default defineComponent({
    emits: ['submit'],
    components: { Modal, CustomButton },
    props: {
        submitButtonLabel: {
            type: String,
            default: 'Confirm',
        },
        cancelButtonLabel: {
            type: String,
            default: 'Cancel',
        },
        header: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'w-100 h-60',
        },
        submitButtonColors: {
            type: String,
            default: 'text-white bg-gray-600',
        },
    },
});
