import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardContentHeader = _resolveComponent("DashboardContentHeader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DashboardContentHeader, {
      "icon-source": "/icons/ic_users.svg",
      header: _ctx.$t('statusboard_index_header')
    }, null, 8, ["header"]),
    _createElementVNode("div", null, _toDisplayString(_ctx.$t('no_customers_yet')), 1)
  ]))
}