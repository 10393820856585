import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, TransitionGroup as _TransitionGroup, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute right-96 my-auto bottom-4" }
const _hoisted_2 = { class: "absolute right-44 my-auto bottom-4" }
const _hoisted_3 = {
  key: 0,
  class: "grid grid-cols-4 gap-2 pt-6"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["id"]
const _hoisted_6 = {
  key: 0,
  class: "tems-center justify-center rounded-full border-2 py-2 px-4 cursor-move"
}
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardContentHeader = _resolveComponent("DashboardContentHeader")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_ModifyThumbnailModal = _resolveComponent("ModifyThumbnailModal")!
  const _component_ConfirmDeleteModal = _resolveComponent("ConfirmDeleteModal")!
  const _component_InfoTable = _resolveComponent("InfoTable")!
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_VueDraggableNext = _resolveComponent("VueDraggableNext")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DashboardContentHeader, {
      "icon-source": "/icons/ic_users.svg",
      header: _ctx.playlist !== null ? _ctx.playlist.name : _ctx.$t('playlist_name_header')
    }, null, 8, ["header"]),
    (_ctx.playlist !== null)
      ? (_openBlock(), _createBlock(_component_Card, {
          key: 0,
          class: "col-span-2",
          header: _ctx.$t('playlist_header')
        }, {
          "header-action": _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ModifyThumbnailModal, {
                form: { playlist: _ctx.playlist, name: _ctx.playlist.name, id: _ctx.playlist.id }
              }, {
                default: _withCtx(({ open }) => [
                  _createVNode(_component_CustomButton, {
                    class: "px-2 mt-auto mx-auto w-48",
                    colors: "text-white bg-green-700",
                    onClick: open
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('playlistthumbnail_btn')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              }, 8, ["form"])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ConfirmDeleteModal, {
                form: { playlist: _ctx.playlist, name: _ctx.playlist.name, id: _ctx.playlist.id }
              }, {
                default: _withCtx(({ open }) => [
                  _createVNode(_component_CustomButton, {
                    class: "px-2 mt-auto mx-auto w-48",
                    colors: "text-white bg-red-700",
                    onClick: open
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('playlistdelete_btn')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              }, 8, ["form"])
            ])
          ]),
          default: _withCtx(() => [
            (_ctx.playlist && _ctx.playlist.thumbnail)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('playlistThumbnail_lbl')), 1),
                  _createElementVNode("img", {
                    src: _ctx.playlist.thumbnail,
                    class: "max-w-16"
                  }, null, 8, _hoisted_4)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_InfoTable, { items: _ctx.infoTableItems }, null, 8, ["items"]),
            _createVNode(_component_VueDraggableNext, {
              modelValue: _ctx.list,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
              move: _ctx.checkMove,
              onChange: _ctx.dragChange
            }, {
              default: _withCtx(() => [
                _createVNode(_TransitionGroup, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (element, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        id: element.video.id,
                        key: element.id,
                        class: "grid grid-cols-4 gap-2 pt-6"
                      }, [
                        _createElementVNode("div", null, [
                          (element.video.id !== 'new')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(index + 1), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createVNode(_component_CustomSelect, {
                          value: element.selectedVideo,
                          "onUpdate:value": ($event: any) => ((element.selectedVideo) = $event),
                          class: "text-black",
                          values: element.video.id !== 'new' ? _ctx.videos : [{ value: -1, name: _ctx.$t('playlist_add_video') }, ..._ctx.videos],
                          onUpdateSelection: ($event: any) => (_ctx.updateVideo(element, $event))
                        }, null, 8, ["value", "onUpdate:value", "values", "onUpdateSelection"]),
                        _createElementVNode("div", null, [
                          (element.video.id !== 'new')
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: "max-h-40",
                                src: `https://${_ctx.customer.domain}/${element.video.bunnyId}/${element.video?.thumbnail ?? 'thumbnail.jpg'}`
                              }, null, 8, _hoisted_7))
                            : _createCommentVNode("", true)
                        ])
                      ], 8, _hoisted_5))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "move", "onChange"]),
            (_ctx.changes)
              ? (_openBlock(), _createBlock(_component_CustomButton, {
                  key: 1,
                  type: "submit",
                  class: "w-full px-6 text-lg",
                  onClick: _ctx.save
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('playlist_update')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"]))
      : _createCommentVNode("", true)
  ]))
}