
import { defineComponent, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { default as FileInput } from '@uppy/vue/src/file-input';
import { StatusBar } from '@uppy/vue';
import Tus from '@uppy/tus';
import Uppy from '@uppy/core';
import Modal from '@/components/ui/Modal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { Video, Customer } from '@/types';
import { ActionType, store } from '@/plugins/store';

import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';

export default defineComponent({
    emits: [],
    components: { Modal, CustomButton, CustomInput, Spinner, FileInput, StatusBar },
    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const routeId = route.params.id as string;
        const customer = computed(() => (store.state.user?.customers as Customer[]).find((customer) => customer.id === routeId));
        const enableSaveBtn = ref(false);
        const fileData = ref({ sign: '', expire: 0, videoId: '' });
        const bunnyId = ref('');
        const name = ref('');

        return {
            t,
            toast: useToast(),
            createVideo: false,
            name,
            bunnyId,
            customer,
            uppy: computed(() =>
                new Uppy({ restrictions: { maxNumberOfFiles: 1, allowedFileTypes: ['video/*'] }, debug: true, autoProceed: false })
                    .use(Tus, {
                        endpoint: `https://video.bunnycdn.com/tusupload`,
                        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
                        onBeforeRequest: (req: any) => {
                            return new Promise<void>((resolve) => {
                                const request = req.getUnderlyingObject();
                                request.setRequestHeader('AuthorizationSignature', `${fileData.value.sign}`);
                                request.setRequestHeader('AuthorizationExpire', fileData.value.expire);
                                request.setRequestHeader('LibraryId', `${customer.value?.libId}`);
                                request.setRequestHeader('VideoId', `${fileData.value.videoId}`);
                                resolve();
                            });
                        },
                    })
                    .on('file-added', async (file: any) => {
                        fileData.value.expire = new Date().getTime() + 1000 * 60 * 60;
                        if (bunnyId.value === '') {
                            const request = {
                                method: 'POST',
                                body: JSON.stringify({ title: name.value !== '' ? name.value : file.name }),
                                headers: {
                                    Accept: 'application/json',
                                    AccessKey: customer.value?.apiKey ?? '',
                                    'Content-Type': 'application/*+json',
                                },
                            };
                            const response = await fetch(`https://videocdn.mkbl.dk/library/${customer.value?.libId ?? ''}/videos`, request);
                            const json = await response.json();
                            bunnyId.value = json.guid;
                        }
                        fileData.value.videoId = bunnyId.value;
                        fileData.value.sign = Array.from(
                            new Uint8Array(
                                await crypto.subtle.digest('SHA-256', new TextEncoder().encode(`${customer.value?.libId ?? ''}${customer.value?.apiKey ?? ''}${fileData.value.expire}${fileData.value.videoId}`))
                            )
                        )
                            .map((b) => b.toString(16).padStart(2, '0'))
                            .join('');
                    })
                    .on('error', (error: any) => {
                        console.log(error);
                    })
                    .on('complete', (result: any) => {
                        enableSaveBtn.value = true;
                    })
            ),
            enableSaveBtn,
        };
    },
    methods: {
        async onSubmitClicked(close: CallableFunction) {
            if (this.name.length < 3) {
                this.toast.error(this.t('addVideo_errorNameLength'));
                return;
            }
            if (this.customer === undefined) return;
            try {
                this.createVideo = true;
                const video: Video | null = await store.dispatch(ActionType.CreateVideo, { form: { name: this.name, customer: this.customer, bunnyId: this.bunnyId } });
                if (video !== null) {
                    this.createVideo = false;
                    this.toast.success(this.t('addVideo_success'));
                    this.$router.push({ name: 'Video', params: { customerId: this.customer.id, videoId: video.id } });
                    close();
                } else this.toast.error(this.t('addVideo_errorGeneral'));
            } catch (error: any) {
                this.toast.error(this.t('addVideo_errorGeneral'));
            }
        },
    },
    beforeDestroy() {
        this.uppy.close();
    },
});
