
import { defineComponent, computed, ref, unref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { VueDraggableNext } from 'vue-draggable-next';
import { Customer, Video } from '@/types';
import Card from '@/components/common/layouts/Card.vue';
import DashboardContentHeader from '@/components/common/DashboardContentHeader.vue';
import { store, ActionType } from '@/plugins/store';
import ConfirmDeleteModal from '@/views/dashboard/customers/customer/playlists/playlist/ConfirmDeleteModal.vue';
import ModifyThumbnailModal from '@/views/dashboard/customers/customer/playlists/playlist/ModifyThumbnailModal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import InfoTable, { InfoTableItem } from '@/components/common/tables/InfoTable.vue';

export default defineComponent({
    components: { DashboardContentHeader, Card, ConfirmDeleteModal, CustomButton, InfoTable, VueDraggableNext, CustomSelect, ModifyThumbnailModal },
    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const routeId = route.params.customerId as string;
        const customer = computed(() => {
            const filtered = computed(() => (computed(() => (store.state.user !== null ? store.state.user.customers : [])).value as Array<Customer>).filter((customer) => customer.id === routeId));
            if (filtered.value.length > 0) {
            } else if (store.state.user) store.dispatch(ActionType.GetCustomer, { form: { id: routeId } });
            return filtered.value.length > 0 ? filtered.value[0] : null;
        });

        const playlist = computed(() => {
            const filtered = computed(() => (computed(() => (store.state.user !== null ? store.state.user.customers : [])).value as Array<Customer>).filter((customer) => customer.id === routeId));
            if (filtered.value.length > 0) {
            } else if (store.state.user) store.dispatch(ActionType.GetCustomer, { form: { id: routeId } });
            const customer = filtered.value.length > 0 ? filtered.value[0] : null;
            if (customer) {
                const playlist = customer.playlists.find((playlist) => playlist.id === route.params.playlistId);
                return playlist;
            }
            return null;
        });

        const list = ref(
            [...(playlist.value?.videos ?? []), { name: 'Add video', bunnyId: 'new', id: 'new', customer: customer.value, playlist } as Video].map((video: Video, index: number) => ({
                id: index,
                video,
                selectedVideo: video.id,
            }))
        );

        watch(customer, (newCustomer) => {
            if (newCustomer !== null) {
                list.value = [
                    ...(newCustomer.playlists.find((list) => list.id === route.params.playlistId)?.videos ?? []),
                    { name: 'Add video', bunnyId: 'new', id: 'new', customer: customer.value, playlist } as Video,
                ].map((video: Video, index: number) => ({ id: index, video, selectedVideo: video.id }));
            }
        });
        return {
            t,
            unref,
            toast: useToast(),
            playlist,
            videos: computed(() =>
                (store.state.user !== null ? (store.state.user.customers as Customer[]).find((customer) => customer.id === routeId)?.videos ?? [] : []).map((video) => ({ name: video.name, value: video.id }))
            ),
            list,
            customer,
            changes: ref(false),
        };
    },
    computed: {
        infoTableItems(): InfoTableItem[] {
            return [{ id: 'name', name: this.t('playlist_name_lbl'), value: this.playlist?.name || '' }];
        },
    },
    methods: {
        deleteSpot(event: any) {
            console.log('here todo', event);
        },
        async save() {
            if (this.customer) {
                const playlist = this.customer.playlists.find((playlist) => playlist.id === this.playlist?.id);
                if (playlist) {
                    playlist.videos = this.list.filter((item) => item.video.id !== 'new').map((element) => element.video);
                    await store.dispatch(ActionType.UpdatePlaylist, { form: { playlist, customer: this.customer } });
                    this.changes = false;
                }
            }
        },
        dragChange(event: any) {
            const videos = this.list.filter((video) => video.video.id !== 'new').map((video) => video.video);
            this.playlist!.videos = event.moved && event.moved.oldIndex !== event.moved.newIndex ? this.arraymove(videos, event.moved.oldIndex, event.moved.newIndex) : videos;
            this.changes = true;
        },
        updateVideo(element: any, value: string) {
            if (element.video.id === 'new' && this.playlist) {
                const video = this.customer?.videos.find((video) => video.id === value);
                if (video) {
                    const addNew = this.list.pop();
                    this.list.push({ id: this.list.length, video, selectedVideo: video.id });
                    if (addNew) {
                        addNew.id = this.list.length;
                        this.list.push(addNew);
                    }
                }
            }
            this.changes = true;
        },
        checkMove(evt: any) {
            return evt.dragged.id !== 'new';
        },
        arraymove(arr: any[], fromIndex: number, toIndex: number) {
            const element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
            return arr;
        },
    },
});
