import { ColDef } from 'ag-grid-community';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { User } from '@/types';

export default function useUsersTableSettings(users: any) {
    const { t } = useI18n();
    const ROW_HEIGHT = 92;
    const headers = [
        { headerName: t('composable_users_name'), field: 'displayName', sortable: true },
        { headerName: t('composable_users_email'), field: 'email', sortable: true },
        { headerName: t('composable_users_Customers'), field: '_customers', sortable: true },
        { headerName: t('composable_users_roles'), field: '_roles', sortable: true },
        { headerName: '', field: 'action', sortable: false, width: 16, headerClass: '!bg-white', cellClass: '!p-0', cellRendererFramework: 'ActionCellRenderer' },
    ] as ColDef[];

    const onItemClicked = (item: User) => {
    };

    const styledUsers = computed(() => {
        return (
            users.value.map((currentUser: User) => {
                return {
                    ...currentUser,
                    _customers: Object.entries(currentUser.customers).map((customer) => `${customer[1]}`),
                    _roles: Object.entries(currentUser.roles).map((role) => `${role[1]}`),
                };
            }) || []
        );
    });

    return { ROW_HEIGHT, headers, onItemClicked, styledUsers };
}
