
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { Customer } from '@/types';
import DashboardContentHeader from '@/components/common/DashboardContentHeader.vue';
import CustomerTabs from '@/views/dashboard/customers/customer/CustomerTabs.vue';
import { store, ActionType } from '@/plugins/store';

export default defineComponent({
    components: { DashboardContentHeader, CustomerTabs },
    setup() {
        const route = useRoute();
        const routeId = route.params.id as string;
        return {
            customer: computed(() => {
                const filtered = computed(() => (computed(() => (store.state.user !== null ? store.state.user.customers : [])).value as Array<Customer>).filter((customer) => customer.id === routeId));
                if (filtered.value.length > 0) {
                } else if (store.state.user) store.dispatch(ActionType.GetCustomer, { form: { id: routeId } });
                return filtered.value.length > 0 ? filtered.value[0] : null;
            }),
        };
    },
});
