import { createApp, nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
import { MAlocalization } from 'ma-localization-js';
import { toast, options as toastOptions } from '@/plugins/toastification';
import App from '@/App.vue';
import router from '@/plugins/router';
import { store } from '@/plugins/store';
import '@/assets/css/index.css';
import defaultTranslations from '@/plugins/translations/strings.json';

const i18n = createI18n({ legacy: false, locale: localStorage.getItem('locale') ?? 'en', messages: defaultTranslations });
let trans: any;
let availableLocales = ['en', 'da'];

async function loadLocaleMessages() {
    if (!trans) trans = await MAlocalization('pDNY7IC8mDQKHiPioxJ6', 'deb43c85-c6be-45e0-8620-99d925c1a9be');
    availableLocales = trans.getAvailableLanguages();
    availableLocales.forEach((locale) => {
        const msgs = trans.getVue(locale);
        i18n.global.setLocaleMessage(locale, msgs);
    });
    return nextTick();
}

createApp(App).use(i18n).use(store).use(router).use(toast, toastOptions).mount('#app');
loadLocaleMessages();
