
import { defineComponent, ref } from 'vue';
import { Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';

export type CustomSelectValue = { name: string; value: any };

export default defineComponent({
    components: { Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption, SelectorIcon, CheckIcon },
    emits: ['updateSelection'],
    props: {
        value: {
            type: String,
            required: true,
        },
        values: {
            type: Array as () => CustomSelectValue[],
            required: true,
        },
        padding: {
            type: String,
            default: 'py-3 px-4',
        },
    },
    setup(props) {
        const selection = ref(props.values.find((value) => value.value === props.value) ?? props.values[0]);
        return {
            selection,
            changedSelection: (event:any)=>{
                selection.value = props.values.find((v)=>v.value==event) ?? props.values[0];
            }
        }
    },
    watch: {
        selection: {
            handler: function (val) {
                this.$emit('updateSelection', val.value);
                
            },
        },
    },
});
