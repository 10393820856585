
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { ActionType, store } from '@/plugins/store';
import { Customer } from '@/types';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';

export default defineComponent({
    components: { ConfirmationModal },
    props: {
        form: {
            type: Object as () => {
                customer: Customer;
                name: string;
                id: string;
            },
            required: true,
        },
        closeParent: {
            type: Function,
            required: true,
        },
    },
    data() {
        const { t } = useI18n();
        return {
            toast: useToast(),
            modalMessage: t('Customer_Customer_deleteUser_confirmMsg', this.form.name),
            t,
        };
    },
    methods: {
        onSubmit(close: CallableFunction): any {
            store
                .dispatch(ActionType.RemoveUserFromCustomer, { form: { customer: this.$props.form.customer, id: this.$props.form.id } })
                .then(() => {
                    this.toast.success(this.t('Customer_Customer_deleteUser_success'));
                })
                .catch(() => {
                    this.toast.error(this.t('Customer_Customer_deleteUser_errorGeneral'));
                });
            close();
        },
    },
});
