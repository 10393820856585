import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardContentHeader = _resolveComponent("DashboardContentHeader")!
  const _component_UsersTable = _resolveComponent("UsersTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DashboardContentHeader, {
      "icon-source": "/icons/ic_users.svg",
      header: _ctx.$t('users_user_header')
    }, null, 8, ["header"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UsersTable)
    ])
  ]))
}