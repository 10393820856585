import { ActionContext, ActionTree } from 'vuex';
import { getStorage, uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import { State } from '@/plugins/store/state';
import { Mutations, MutationType } from '@/plugins/store/mutations';
import { User, Customer, Video, Playlist } from '@/types';
import FirebaseClient, { CallableFunctions, Collection } from '@/plugins/store/api/FirebaseClient';
import ApiRequest from '@/plugins/store/api/ApiRequest';

type RequestOptions = { ignoreCache?: boolean };

export enum ActionType {
    GetFirebaseUser = 'GET_FIREBASEUSER',
    SetFirebaseUser = 'SET_FIREBASEUSER',
    AddFirebaseUser = 'ADD_FIREBASEUSER',
    EditCustomer = 'EDIT_Customer',
    RemoveUserFromCustomer = 'REMOVE_USER_FROM_Customer',
    GetAllCustomers = 'GET_ALL_Customers',
    GetCustomer = 'GET_Customer',
    GetAllUsers = 'GET_ALL_USERS',
    CreateCustomer = 'CREATE_PROEJCT',
    GetCustomerFromId = 'GET_Customer_FROM_ID',
    CreateVideo = 'CREATE_VIDEO',
    DeleteVideo = 'DELETE_VIDEO',
    CreatePlaylist = 'CREATE_PLAYLIST',
    UpdatePlaylist = 'UPDATE_PLAYLIST',
    DeletePlaylist = 'DELETE_PLAYLIST',
    GrantRoleToUser = 'GRANT_ROLE_TO_USER',
    addUserToCustomer = 'ADD_USER_TO_Customer',
    GetCustomerStatistics = 'GET_Customer_STATISTICS',
    GetCustomerStatisticsAll = 'GET_Customer_STATISTICS_ALL',
    UpdateThumbnail = 'UPDATE_THUMBNAIL',
    getSignedURLs = 'GET_SIGNED_URLS',
    UpdatePlaylistThumbnail = 'UPDATE_PLAYLIST_THUMBNAIL',
}

const actionsObject = {
    async [ActionType.AddFirebaseUser](store: ActionAugments, args: { form: { email: string; name: string; uid: string } }) {
        // TODO add mail validation for GDPR reasons Add login lock till mail is validated
        return ApiRequest.send<any>(FirebaseClient.setDocument(Collection.Users, args.form.uid, { displayName: args.form.name, email: args.form.email, forceRefresh: false, Customers: [], roles: [] }, true));
    },
    async [ActionType.SetFirebaseUser](store: ActionAugments, args: { form: { user: User } }) {
        const data = {
            displayName: args.form.user.displayName,
            email: args.form.user.email,
            roles: args.form.user.roles,
            customers: (args.form.user.customers as Customer[]).map((customer) => customer.id),
            forceRefresh: args.form.user.forceRefresh,
        };
        await ApiRequest.send<any>(FirebaseClient.setDocument(Collection.Users, args.form.user.id, data, true));
        return store.commit(MutationType.SetFirebaseUser, args.form.user);
    },
    async [ActionType.GetFirebaseUser](store: ActionAugments, args: { options?: RequestOptions; form: { id: string } }) {
        const user = await ApiRequest.send<User>(FirebaseClient.getDocumentData(Collection.Users, args.form.id));
        if (user.customers && (user.customers as string[]).length > 0) {
            user.customers = await Promise.all(
                (user.customers as string[]).map((customer: string) => {
                    return store.dispatch(ActionType.GetCustomer, { form: { id: customer } });
                })
            );
            // TODO fetch all Customers from teams membership
        } else user.customers = [];
        return user;
    },
    async [ActionType.GetAllCustomers](store: ActionAugments) {
        let customers = store.state.allCustomers;
        if (customers === null) {
            customers = await ApiRequest.send<Customer[]>(FirebaseClient.getCollection(Collection.Customers));
            store.commit(MutationType.SetAllCustomers, { customers });
        }
        return customers;
    },
    async [ActionType.GetCustomer](store: ActionAugments, args: { options?: RequestOptions; form: { id: string } }) {
        const customer = await ApiRequest.send<Customer>(FirebaseClient.getDocumentData(Collection.Customers, args.form.id));
        customer.videos = await ApiRequest.send<Video[]>(FirebaseClient.getCollection(Collection.Customers, `${args.form.id}/videos`));
        customer.playlists = (await ApiRequest.send<Playlist[]>(FirebaseClient.getCollection(Collection.Customers, `${args.form.id}/playlists`))).map((playlist) => {
            playlist.videos = (playlist.videoIds ?? [])
                .map((videoId) => {
                    return customer.videos.find((v) => v.id == videoId);
                })
                .filter((v) => v !== undefined) as Video[];
            return playlist;
        });
        return customer;
    },
    async [ActionType.GetAllUsers](store: ActionAugments) {
        let users = store.state.users;
        if (users === null) {
            users = await ApiRequest.send<User[]>(FirebaseClient.getCollection(Collection.Users));
            store.commit(MutationType.SetAllUsers, { users });
        }
        return users;
    },
    async [ActionType.CreateCustomer](store: ActionAugments, args: { options?: RequestOptions; form: { name: string; libId: string; apiKey: string; doamin: string; pullZoneId: string } }) {
        const user = store.state.user;
        if (user === null) return null;
        const data = {
            name: args.form.name,
            roles: { [user.id]: { displayName: user.email, role: 'owner' } },
            libId: args.form.libId,
            apiKey: args.form.apiKey,
            domain: args.form.doamin,
            pullZoneId: args.form.pullZoneId,
            videos: [],
            playlists: [],
        };
        const docRef = await ApiRequest.send<any>(FirebaseClient.setDocument(Collection.Customers, '', data, true));
        const customer = { id: docRef.id, ...data };
        (user.customers as Customer[]).push({ ...customer, videos: [], playlists: [] });
        await ApiRequest.send<any>(FirebaseClient.setDocument(Collection.Users, user.id, { customers: Object.values(user.customers).map((item: Customer) => item.id) }, true));
        return { ...customer, videos: [], playlists: [] };
    },
    async [ActionType.UpdateThumbnail](store: ActionAugments, args: { options?: RequestOptions; form: { id: string; customer: Customer; thumbnailurl: string } }) {
        await ApiRequest.send(<any>FirebaseClient.setDocument(`${Collection.Customers}/${args.form.customer.id}/videos/`, args.form.id, { thumbnail: args.form.thumbnailurl }, true));
        const video = args.form.customer.videos.find((v) => v.id === args.form.id);
        if (video) video.thumbnail = args.form.thumbnailurl;
    },
    async [ActionType.getSignedURLs]() {
        return FirebaseClient.callFunction(CallableFunctions.getSignedURLs);
    },
    async [ActionType.EditCustomer](store: ActionAugments, args: { options?: RequestOptions; form: { id: string; name: string } }) {
        return ApiRequest.send<any>(FirebaseClient.setDocument(Collection.Customers, args.form.id, { name: args.form.name }, true));
    },
    async [ActionType.RemoveUserFromCustomer](store: ActionAugments, args: { options?: RequestOptions; form: { customer: Customer; id: string } }) {
        delete (args.form.customer.roles as { [key: string]: Object })[args.form.id];
        return ApiRequest.send<any>(FirebaseClient.setDocument(Collection.Customers, args.form.customer.id, { roles: args.form.customer.roles }, true));
    },
    async [ActionType.CreateVideo](store: ActionAugments, args: { options?: RequestOptions; form: { name: string; customer: Customer; bunnyId: string } }) {
        const user = store.state.user;
        if (user === null) return null;
        const data = { name: args.form.name, bunnyId: args.form.bunnyId, customerId: args.form.customer.id };
        const video = await ApiRequest.send<any>(FirebaseClient.setDocument(`${Collection.Customers}/${args.form.customer.id}/videos`, '', data, true));
        args.form.customer.videos.push({ id: video.id, customer: args.form.customer, name: args.form.name, bunnyId: args.form.bunnyId });
        return { id: video.id, customer: args.form.customer, name: args.form.name, bunnyId: args.form.bunnyId };
    },
    async [ActionType.DeleteVideo](store: ActionAugments, args: { options?: RequestOptions; form: { video: Video; id: string } }) {
        args.form.video.customer.videos = args.form.video.customer.videos.filter((video: Video) => video.id !== args.form.id);
        await fetch(`https://videocdn.mkbl.dk/library/${args.form.video.customer.libId}/videos/${args.form.id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                AccessKey: args.form.video.customer.apiKey,
            },
        });
        return ApiRequest.send<any>(FirebaseClient.deleteDocument(`${Collection.Customers}/${args.form.video.customer.id}/videos`, args.form.video.id));
    },
    async [ActionType.CreatePlaylist](store: ActionAugments, args: { options?: RequestOptions; form: { name: string; customer: Customer } }) {
        const user = store.state.user;
        if (user === null) return null;
        const data = { name: args.form.name, customerId: args.form.customer.id, videos: [] };
        const playlist = await ApiRequest.send<any>(FirebaseClient.setDocument(`${Collection.Customers}/${args.form.customer.id}/playlists`, '', data, true));
        args.form.customer.playlists.push({ id: playlist.id, name: args.form.name, videos: [], videoIds: [] });
        return { id: playlist.id, name: args.form.name, videos: [], videoIds: [] };
    },
    async [ActionType.UpdatePlaylist](store: ActionAugments, args: { options?: RequestOptions; form: { playlist: Playlist; customer: Customer } }) {
        const user = store.state.user;
        if (user === null) return null;
        return ApiRequest.send<any>(
            FirebaseClient.setDocument(
                `${Collection.Customers}/${args.form.customer.id}/playlists`,
                args.form.playlist.id,
                { name: args.form.playlist.name, videoIds: args.form.playlist.videos.map((video) => video.id) },
                true
            )
        );
    },
    async [ActionType.DeletePlaylist](store: ActionAugments, args: { options?: RequestOptions; form: { playlist: Playlist; customerId: string } }) {
        const customer = store.state.customer?.find((customer) => customer.id === args.form.customerId);
        if (!customer) return;
        customer.playlists = customer.playlists.filter((playlist: Playlist) => playlist.id !== args.form.playlist.id);
        return ApiRequest.send<any>(FirebaseClient.deleteDocument(`${Collection.Customers}/${customer.id}/playlists`, args.form.playlist.id));
    },
    async [ActionType.GrantRoleToUser](store: ActionAugments, args: { options?: RequestOptions; form: { userId: string; role: string } }) {
        return ApiRequest.send<any>(FirebaseClient.callFunction(CallableFunctions.GRANT_ROLE, { uid: args.form.userId, claim: args.form.role }));
    },
    async [ActionType.addUserToCustomer](store: ActionAugments, args: { options?: RequestOptions; form: { customerId: string; email: string; role: string } }) {
        return ApiRequest.send<any>(FirebaseClient.callFunction(CallableFunctions.AddUserToCustomer, { customerId: args.form.customerId, role: args.form.role, email: args.form.email }));
    },
    async [ActionType.GetCustomerStatistics](store: ActionAugments, args: { options?: RequestOptions; form: { customerId: string } }) {
        (store.state.user!.customers as Customer[]).find((customer) => customer.id === args.form.customerId)!.statistics = await ApiRequest.send<any>(
            FirebaseClient.callFunction(CallableFunctions.CustomerStatistics, { customerId: args.form.customerId })
        );
        return true;
    },
    async [ActionType.GetCustomerStatisticsAll](store: ActionAugments, args: { options?: RequestOptions; form: { customerId: string } }) {
        (store.state.user!.customers as Customer[]).find((customer) => customer.id === args.form.customerId)!.statistics = await ApiRequest.send<any>(
            FirebaseClient.callFunction(CallableFunctions.CustomerStatisticsAll)
        );
        return true;
    },
    async [ActionType.UpdatePlaylistThumbnail](store: ActionAugments, args: { options?: RequestOptions; form: { playlist: Playlist; customerId: string; file: any } }) {
        const filePath = `customers/${args.form.customerId}/playlist/${args.form.playlist.id}`;
        const storage = getStorage();
        const uRef = ref(storage, filePath);
        const uploadRef = await uploadBytes(uRef, args.form.file);
        const thumbnail = await getDownloadURL(uploadRef.ref);
        await ApiRequest.send(<any>FirebaseClient.setDocument(`${Collection.Customers}/${args.form.customerId}/playlists/`, args.form.playlist.id, { thumbnail }, true));
    },
};

/* Boilerplate for type safety */
export type ActionAugments = Omit<ActionContext<State, State>, 'commit'> & {
    commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};
export type Actions = typeof actionsObject;
export const actions: ActionTree<State, State> & Actions = actionsObject;
