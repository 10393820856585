import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mt-10"
}
const _hoisted_2 = { class: "my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardContentHeader = _resolveComponent("DashboardContentHeader")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_AddVideoModal = _resolveComponent("AddVideoModal")!
  const _component_GenericTable = _resolveComponent("GenericTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.embeded)
      ? (_openBlock(), _createBlock(_component_DashboardContentHeader, {
          key: 0,
          "icon-source": "/icons/ic_users.svg",
          header: _ctx.customer !== null ? _ctx.customer.name : _ctx.$t('Customers_Customer_name')
        }, null, 8, ["header"]))
      : _createCommentVNode("", true),
    (_ctx.customer !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AddVideoModal, null, {
              default: _withCtx(({ open }) => [
                _createVNode(_component_CustomButton, {
                  class: "pl-4 pr-4 mb-4",
                  "text-class": "text-lg font-semibold",
                  onClick: open
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('video_create')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_GenericTable, {
            items: _ctx.styledVideos,
            headers: _ctx.headers,
            options: { rowHeight: _ctx.ROW_HEIGHT },
            onItemClicked: _ctx.onItemClicked
          }, null, 8, ["items", "headers", "options", "onItemClicked"])
        ]))
      : _createCommentVNode("", true)
  ]))
}