import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "space-x-12 text-lg flex border-b border-black border-opacity-10" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tab.name,
          class: "flex"
        }, [
          _createElementVNode("a", {
            class: _normalizeClass([{ 'opacity-100 border-b-4 border-black': _ctx.computedActiveTab === tab, 'opacity-50': _ctx.computedActiveTab !== tab }, "leading-10 min-h-10 font-semibold cursor-pointer whitespace-nowrap"]),
            onClick: ($event: any) => (_ctx.computedActiveTab = tab)
          }, _toDisplayString(tab.name), 11, _hoisted_2)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}