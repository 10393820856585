
import { defineComponent, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/ui/Modal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { User} from '@/types';
import { ActionType, store } from '@/plugins/store';


export default defineComponent({
    emits: [],
    components: { Modal, CustomButton, CustomSelect,  Spinner },
    props: {
        users: {
            type: Object as () => User[],
            required: true,
        },
    },
    setup(props) {
        const users = props.users.map((user)=>{return {name:user.email,value:user.id}});
        console.log(users);
        const { t } = useI18n();
        return {
            t,
            toast: useToast(),
            role: ref('allCustomers'),
            user: ref(users[0]?.value??''),
            users,
            grantInProgress: ref(false),
        };
    },
    methods: {
        async onSubmitClicked(close: CallableFunction) {
            try {
                this.grantInProgress = true;
                const result = await store.dispatch(ActionType.GrantRoleToUser, { form: { userId: this.user, role:this.role } });
                if (result.success) {
                    this.grantInProgress = false;
                    this.toast.success(this.t('grantRole_success'));
                    close();
                } else this.toast.error(this.t('grantRole_errorGeneral'));
            } catch (error: any) {
                this.toast.error(this.t('grantRole_errorGeneral'));
            }
        },
    }, 
});
