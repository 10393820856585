import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid gap-10 xl:grid-cols-3" }
const _hoisted_2 = {
  key: 0,
  class: "absolute right-44 my-auto bottom-4"
}
const _hoisted_3 = {
  key: 1,
  class: "absolute right-10 my-auto bottom-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_AddUserModal = _resolveComponent("AddUserModal")!
  const _component_EditCustomerModal = _resolveComponent("EditCustomerModal")!
  const _component_InfoTable = _resolveComponent("InfoTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, {
      class: "col-span-2",
      header: _ctx.$t('Customers_Customer_information')
    }, {
      "header-action": _withCtx(() => [
        (_ctx.user !== null && _ctx.user.customerAccess(_ctx.customer.id, _ctx.user, 'editor'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_AddUserModal, { customer: _ctx.customer }, {
                default: _withCtx(({ open }) => [
                  _createVNode(_component_CustomButton, {
                    class: "w-32",
                    "text-class": "text-lg font-semibold",
                    onClick: open
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Customers_Customer_addUser')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              }, 8, ["customer"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.user !== null && _ctx.user.customerAccess(_ctx.customer.id, _ctx.user, 'editor'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_EditCustomerModal, { customer: _ctx.customer }, {
                default: _withCtx(({ open }) => [
                  _createVNode(_component_CustomButton, {
                    class: "w-32",
                    "text-class": "text-lg font-semibold",
                    onClick: open
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Customers_Customer_editCustomer')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              }, 8, ["customer"])
            ]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_InfoTable, { items: _ctx.infoTableItems }, null, 8, ["items"])
      ]),
      _: 1
    }, 8, ["header"])
  ]))
}