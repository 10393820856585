import { MutationTree } from 'vuex';
import { State } from '@/plugins/store/state';
import { User, Customer } from '@/types';

export enum MutationType {
    SetFirebaseUser = 'SET_FIREBASEUSER',
    SetTranskeys = 'SET_TRANSKEYS',
    SetCustomerBundles = 'SET_Customer_BUNDLES',
    SetAllCustomers = 'SET_ALL_Customers',
    SetAllUsers = 'SET_ALL_USERS',
    SetAllTeams = 'SET_ALL_TEAMS',
    SetCustomer = 'SST_CUSTOMER'
}
const mutationObject = {
    [MutationType.SetFirebaseUser](state: State, user: User | null) {
        state.user = user;
    },
    [MutationType.SetAllCustomers](state: State, data: { customers: Customer[] }) {
        state.allCustomers = data.customers;
    },
    [MutationType.SetAllUsers](state: State, data: { users: User[] }) {
        state.users = data.users;
    },
};

/* Boilerplate for type safety */
export type Mutations = typeof mutationObject;
export const mutations: MutationTree<State> & Mutations = mutationObject;
