
import { defineComponent } from 'vue';
import DashboardContentHeader from '@/components/common/DashboardContentHeader.vue';
import CustomersTable from '@/views/dashboard/customers/CustomersTable.vue';
import AddCustomerModal from '@/views/dashboard/customers/AddCustomerModal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';

export default defineComponent({
    components: { DashboardContentHeader, CustomersTable, AddCustomerModal, CustomButton },
});
