import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex h-full flex-col" }
const _hoisted_2 = { class: "text-center pt-2" }
const _hoisted_3 = { class: "flex h-full flex-col my-auto" }
const _hoisted_4 = { class: "flex mt-10" }
const _hoisted_5 = {
  key: 0,
  class: "h-6 w-6"
}
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    size: "w-175 h-100",
    padding: "py-10 px-28"
  }, {
    toggle: _withCtx((state) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(state)))
    ]),
    body: _withCtx((state) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('addPlaylist_header')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_CustomInput, {
            value: _ctx.name,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            class: "mt-12",
            placeholder: "Name"
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_CustomButton, {
            disabled: _ctx.createVideo,
            class: _normalizeClass([{ 'cursor-default': _ctx.createVideo }, "px-2 mt-auto mx-auto w-48"]),
            onClick: ($event: any) => (_ctx.onSubmitClicked(state.close))
          }, {
            default: _withCtx(() => [
              (_ctx.createVideo)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _createVNode(_component_Spinner)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('addPlaylist_btnConfirm')), 1))
            ]),
            _: 2
          }, 1032, ["disabled", "class", "onClick"])
        ])
      ])
    ]),
    _: 3
  }))
}