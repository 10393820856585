
import { defineComponent, computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { Customer } from '@/types';
import Card from '@/components/common/layouts/Card.vue';
import DashboardContentHeader from '@/components/common/DashboardContentHeader.vue';
import { store, ActionType } from '@/plugins/store';
import ConfirmDeleteModal from '@/views/dashboard/customers/customer/videos/video/ConfirmDeleteModal.vue';
import UpdateThumbnailModal from '@/views/dashboard/customers/customer/videos/video/UpdateThumbnailModal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import InfoTable, { InfoTableItem } from '@/components/common/tables/InfoTable.vue';

export default defineComponent({
    components: { DashboardContentHeader, Card, ConfirmDeleteModal, UpdateThumbnailModal, CustomButton, InfoTable },
    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const routeId = route.params.customerId as string;
        const encodingStatus = ref('');
        const customer = computed(() => {
            const filtered = computed(() => (computed(() => (store.state.user !== null ? store.state.user.customers : [])).value as Array<Customer>).filter((Customer) => Customer.id === routeId));
            if (filtered.value.length > 0) {
            } else if (store.state.user) store.dispatch(ActionType.GetCustomer, { form: { id: routeId } });
            return filtered.value.length > 0 ? filtered.value[0] : null;
        });

        return {
            t,
            customer,
            encodingStatus,
            video: computed(() => {
                if (customer.value) {
                    const video = customer.value?.videos.find((candidate) => candidate.id === route.params.videoId);
                    if (video && customer.value) video.customer = customer.value;
                    return video;
                }
                return null;
            }),
        };
    },
    watch: {
        customer: {
            immediate: true,
            handler(customer) {
                if (customer) {
                    this.updateVideoStatus(customer);
                }
            },
        },
    },
    methods: {
        updateVideoStatus(customer: any) {
            fetch(`https://video.bunnycdn.com/library/${customer.libId}/videos/${this.video?.bunnyId}`, {
                method: 'GET',
                headers: { Accept: 'application/json', AccessKey: customer.apiKey },
            }).then((response) => {
                response.json().then((data) => {
                    switch (data.status) {
                        case 0:
                            this.encodingStatus = 'Created';
                            break;
                        case 1:
                            this.encodingStatus = 'Uploaded';
                            break;
                        case 2:
                            this.encodingStatus = 'Processing';
                            break;
                        case 3:
                            this.encodingStatus = 'Transcoding';
                            break;
                        case 4:
                            this.encodingStatus = 'Finished';
                            break;
                        case 5:
                            this.encodingStatus = 'Failed';
                            break;
                        case 6:
                            this.encodingStatus = 'Upload failed';
                            break;
                        case 7:
                            this.encodingStatus = 'Transcribing';
                            break;
                        default:
                            this.encodingStatus = 'Unknown';
                    }
                    if (data.status !== 4 && data.status !== 5 && data.status !== 6) {
                        setTimeout(() => {
                            this.updateVideoStatus(customer);
                        }, 20000);
                    }
                });
            });
        },
    },
    computed: {
        infoTableItems(): InfoTableItem[] {
            return [
                { id: 'name', name: this.t('video_name_lbl'), value: this.video?.name || '' },
                { id: 'hls', name: this.t('Video_HLS_lbl'), value: `https://${this.video?.customer.domain}/${this.video?.bunnyId}/playlist.m3u8` },
                { id: 'thumb', name: this.t('Video_thumbnail_lbl'), value: `https://${this.video?.customer.domain}/${this.video?.bunnyId}/${this.video?.thumbnail ?? 'thumbnail.jpg'}` },
                { id: 'encoding_status', name: 'Status', value: this.encodingStatus },
            ];
        },
    },
});
