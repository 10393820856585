
import { defineComponent, computed } from 'vue';
import DashboardContentHeader from '@/components/common/DashboardContentHeader.vue';
import GrantRoleModal from '@/views/admin/GrantRoleModal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import { ActionType, store } from '@/plugins/store';
import { useRouter } from 'vue-router';
import Card from '@/components/common/layouts/Card.vue';

export default defineComponent({
    components: { DashboardContentHeader, Card, GrantRoleModal,CustomButton },
    async created() {
        await store.dispatch(ActionType.GetAllUsers);
    },
    setup() {
        return {
            router: useRouter(),
            user: computed(() => store.state.user),
            users:computed(() => (store.state.users !== null ? store.state.users : [])),
        };
    },
});
