
import { defineComponent, ref,computed } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/ui/Modal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { Playlist ,Customer} from '@/types';
import { ActionType, store } from '@/plugins/store';

export default defineComponent({
    emits: [],
    components: { Modal, CustomButton, CustomInput,  Spinner},
    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const routeId = route.params.id as string;
        const customer = computed(()=>(store.state.user?.customers as Customer[]).find((customer)=>customer.id === routeId));
        const name = ref('');

        return {
            t,
            toast: useToast(),
            createVideo: false,
            name,
            customer,
        };
    },
    methods: {
        async onSubmitClicked(close: CallableFunction) {
            if (this.name.length < 3) {
                this.toast.error(this.t('addPlaylist_errorNameLength'));
                return;
            }
            if(this.customer === undefined)return;
            try {
                this.createVideo = true;
                const playlist: Playlist | null = await store.dispatch(ActionType.CreatePlaylist, { form: { name: this.name, customer:this.customer } });
                if (playlist !== null) {
                    this.createVideo = false;
                    this.toast.success(this.t('addPlaylist_success'));
                    this.$router.push({ name: 'Playlist', params: { customerId:this.customer.id,playlistId: playlist.id } });
                    close();
                } else this.toast.error(this.t('add_errorPlaylistGeneral'));
            } catch (error: any) {
                this.toast.error(this.t('add_errorPlaylistGeneral'));
            }
        },
    }, 
});
