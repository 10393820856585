
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { Customer } from '@/types';
import DashboardContentHeader from '@/components/common/DashboardContentHeader.vue';
import AddVideoModal from '@/views/dashboard/customers/customer/videos/AddVideoModal.vue';
import GenericTable from '@/components/common/tables/genericTable/GenericTable.vue';
import { store, ActionType } from '@/plugins/store';
import useVideosTableSettings from '@/components/common/tables/composables/useVideosTableSettings';
import CustomButton from '@/components/ui/CustomButton.vue';

export default defineComponent({
    components: { DashboardContentHeader, AddVideoModal,GenericTable,CustomButton },
    setup() {
        const route = useRoute();
        const routeId = route.params.id as string;
        return {    
            customer: computed(() => {
                const filtered = computed(() => (computed(() => (store.state.user !== null ? store.state.user.customers : [])).value as Array<Customer>).filter((customer) => customer.id === routeId));
                if (filtered.value.length > 0) {
                } else if (store.state.user) store.dispatch(ActionType.GetCustomer, { form: { id: routeId } });
                return filtered.value.length > 0 ? filtered.value[0] : null;
            }),
            ...useVideosTableSettings(computed(() => {
                return (
                store.state.user !== null ? 
                    (store.state.user.customers as Customer[]).find((customer)=>customer.id === routeId)?.videos :
                     [] ?? 
                     []);
            })),
            embeded: computed(() => route.name !== 'Videos'),
        };
    },
});
