import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { ActionType, store, MutationType } from '@/plugins/store';
import { User, Customer } from '@/types';
import router from '@/plugins/router';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
auth.useDeviceLanguage();

const db = getFirestore(app);

const functions = getFunctions(app, 'europe-west1');
let refreshCheck: any = null;
let userObj: any = null;

auth.onAuthStateChanged(async (user) => {
    if (!user) {
        store.commit(MutationType.SetFirebaseUser, null);
        clearInterval(refreshCheck);
        userObj = null;
    } else {
        userObj = user;
        updateUserObject(false);
        refreshCheck = setInterval(() => updateUserObject(true), 60000);
    }
});

const checkForceUserUpdate = (freeze = false) => {
    if (!freeze && document.visibilityState === 'visible' && userObj !== null) {
        updateUserObject(true);
        refreshCheck = setInterval(() => updateUserObject(true), 60000);
    } else clearInterval(refreshCheck);
};

document.addEventListener('visibilitychange', () => {
    checkForceUserUpdate();
});
document.addEventListener('freeze', () => {
    checkForceUserUpdate(true);
});
document.addEventListener('resume', () => {
    checkForceUserUpdate();
});

// Updates the local user object, if onlyRefresh is set, check if the backend is asking for a local refresh via foreceRefresh property.
const updateUserObject = (onlyRefresh: boolean) => {
    store.dispatch(ActionType.GetFirebaseUser, { form: { id: userObj.uid } }).then(async (firebaseUser) => {
        if (!onlyRefresh || firebaseUser.forceRefresh === true) {
            userObj.getIdToken(true);
            const userIdTokenResults = await userObj.getIdTokenResult();
            await store.dispatch(ActionType.SetFirebaseUser, {
                form: {
                    user: {
                        id: userObj.uid,
                        displayName: firebaseUser.displayName ?? userObj.email,
                        email: userObj.email ?? '',
                        customers: firebaseUser.customers ?? [],
                        roles: (userIdTokenResults.claims.roles as string[]) ?? [],
                        customerAccess,
                        forceRefresh: false,
                    },
                },
            });

            if (router.currentRoute.value.name === 'Dashboard' && (firebaseUser.customers as Customer[]).length > 0) {
                if ((firebaseUser.customers as Customer[]).length === 1) router.push({ name: 'Customer', params: { id: (firebaseUser.customers as Customer[])[0].id } });
                else router.push({ name: 'Customers', params: { id: (firebaseUser.customers as Customer[])[0].id } });
            }
        }
    });
};
const customerAccess = (customerId: string, userObj: User, access: string) => {
    if (userObj.roles.includes('superadmin') || userObj.roles.includes('allCustomers')) return true;
    const customer = (userObj.customers as Customer[]).find((candidate) => customerId === candidate.id);
    const role = customer === undefined ? 'invalid' : customer.roles[userObj.id].role;
    if (access === 'owner') return role === 'owner';
    if (access === 'editor') return ['owner', 'editor'].includes(role);
    return false;
};
export { auth, db, functions };
