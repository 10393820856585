import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardContentHeader = _resolveComponent("DashboardContentHeader")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_GrantRoleModal = _resolveComponent("GrantRoleModal")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DashboardContentHeader, {
      "icon-source": "/icons/ic_users.svg",
      header: _ctx.$t('admin_index_header')
    }, null, 8, ["header"]),
    _createVNode(_component_Card, { class: "col-span-2" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_GrantRoleModal, { users: _ctx.users }, {
            default: _withCtx(({ open }) => [
              _createVNode(_component_CustomButton, {
                class: "pl-4 pr-4 mb-4",
                "text-class": "text-lg font-semibold",
                onClick: open
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('grant_role_btn')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }, 8, ["users"])
        ])
      ]),
      _: 1
    })
  ]))
}