
import { defineComponent, computed } from 'vue';
import GenericTable from '@/components/common/tables/genericTable/GenericTable.vue';
import useCustomersTableSettings from '@/components/common/tables/composables/useCustomersTableSettings';
import { store } from '@/plugins/store';

export default defineComponent({
    components: { GenericTable },
    setup() {
        return useCustomersTableSettings(computed(() => (store.state.user !== null ? store.state.user.customers : [])));
    },
});
