
import { defineComponent, computed } from 'vue';
import GenericTable from '@/components/common/tables/genericTable/GenericTable.vue';
import { User } from '@/types';
import useUsersTableSettings from '@/components/common/tables/composables/useUsersTableSettings';
import { ActionType, store } from '@/plugins/store';

export default defineComponent({
    components: { GenericTable },
    created() {
        store.dispatch(ActionType.GetAllUsers);
    },
    setup() {
        return useUsersTableSettings(computed(() => (store.state.users !== null ? store.state.users : [])));
    },
    methods: {
        onItemClicked(item: User) {
            this.$router.push({ name: 'User', params: { id: item.id } });
        },
    },
});
