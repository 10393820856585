import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericTable = _resolveComponent("GenericTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_GenericTable, {
      items: _ctx.styledUsers,
      headers: _ctx.headers,
      options: { rowHeight: _ctx.ROW_HEIGHT },
      onItemClicked: _ctx.onItemClicked
    }, null, 8, ["items", "headers", "options", "onItemClicked"])
  ]))
}