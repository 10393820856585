
import { defineComponent } from 'vue';
import { signInWithEmailAndPassword, Unsubscribe, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '@/plugins/firebase';
import CustomButton from '@/components/ui/CustomButton.vue';
import { ActionType, store } from '@/plugins/store';

export default defineComponent({
    components: { CustomButton },
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            unsubscribeAuthStateListener: null as null | Unsubscribe,
        };
    },
    created() {
        this.unsubscribeAuthStateListener = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const fbuser = await store.dispatch(ActionType.GetFirebaseUser, { form: { id: user.uid } });
                if (fbuser.email !== undefined) this.$router.push({ name: 'Dashboard' });
            }
        });
    },
    beforeUnmount() {
        if (this.unsubscribeAuthStateListener) {
            this.unsubscribeAuthStateListener();
        }
    },
    methods: {
        async onSubmit() {
            try {
                const user = await signInWithEmailAndPassword(auth, this.form.email, this.form.password);
                if (user) {
                    this.$router.push({ name: 'Dashboard' });
                }
            } catch (error: any) {
                console.log(error.message);
            }
        },
        googleLogin() {
            signInWithPopup(auth, new GoogleAuthProvider())
                .then(async (result) => {
                    if (result.user) {
                        const user = await store.dispatch(ActionType.GetFirebaseUser, { form: { id: result.user.uid } });
                        if (user.email === undefined)
                            store.dispatch(ActionType.AddFirebaseUser, { form: { email: result.user.email as string, name: result.user.email as string, uid: result.user.uid } }).then(() => {
                                this.$router.push({ name: 'Dashboard' });
                            });
                        this.$router.push({ name: 'Dashboard' });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
});
