
import { defineComponent, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/ui/Modal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { Customer } from '@/types';
import { ActionType, store } from '@/plugins/store';

export default defineComponent({
    emits: [],
    components: { Modal, CustomButton, CustomInput, CustomSelect, Spinner },
    setup() {
        const { t } = useI18n();
        return {
            t,
            toast: useToast(),
            createCustomer: false,
            name: ref(''),
            libId: ref(''),
            domain: ref(''),
            apiKey: ref(''),
            pullZoneId: ref(''),
        };
    },
    methods: {
        async onSubmitClicked(close: CallableFunction) {
            if (this.name.length < 3) {
                this.toast.error(this.t('Customer_addCustomer_errorNameLength'));
                return;
            }
            try {
                this.createCustomer = true;
                const customer: Customer | null = await store.dispatch(ActionType.CreateCustomer, { form: { name: this.name,libId:this.libId,doamin:this.domain,apiKey:this.apiKey,pullZoneId:this.pullZoneId } });
                if (customer !== null) {
                    this.createCustomer = false;
                    this.toast.success(this.t('Customer_addCustomer_success'));
                    this.$router.push({ name: 'Customer', params: { id: customer.id } });
                    close();
                } else this.toast.error(this.t('Customer_addCustomer_errorGeneral'));
            } catch (error: any) {
                this.toast.error(this.t('Customer_addCustomer_errorGeneral'));
            }
        },
    },
});
