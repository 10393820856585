
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { ActionType, store } from '@/plugins/store';
import { Video } from '@/types';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    components: { ConfirmationModal },
    props: {
        form: {
            type: Object as () => {
                video: Video;
                name: string;
                id: string;
            },
            required: true,
        },
    },
    data() {
        const { t } = useI18n();
        return {
            router:useRouter(),
            toast: useToast(),
            modalMessage: t('deleteVideo_confirmMsg', this.form.name),
            t,
        };
    },
    methods: {
        onSubmit(close: CallableFunction): any {
            store
                .dispatch(ActionType.DeleteVideo, { form: { video: this.$props.form.video, id: this.$props.form.video.bunnyId } })
                .then(() => {
                    this.toast.success(this.t('deleteVideo_success'));
                    this.router.push({name:'videos',params:{id:this.form.video.customer.id}});
                })
                .catch(() => {
                    this.toast.error(this.t('deleteVideo_errorGeneral'));
                });
            close();
        },
    },
});
