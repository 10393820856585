import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex h-full flex-col" }
const _hoisted_2 = { class: "text-center pt-2" }
const _hoisted_3 = { class: "flex h-full flex-col my-auto" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "flex space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    size: _ctx.size,
    padding: "px-10 py-6"
  }, {
    toggle: _withCtx((state) => [
      _renderSlot(_ctx.$slots, "toggle", _normalizeProps(_guardReactiveProps(state)))
    ]),
    body: _withCtx((state) => [
      _renderSlot(_ctx.$slots, "body", _normalizeProps(_guardReactiveProps(state)), () => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.header), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "text-center opacity-80 mt-4",
              innerHTML: _ctx.message
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_CustomButton, {
              class: "px-2 mt-auto mx-auto w-48",
              colors: "bg-gray-400 text-white",
              onClick: state.close
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.cancelButtonLabel), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_CustomButton, {
              class: "px-2 mt-auto mx-auto w-48",
              colors: _ctx.submitButtonColors,
              onClick: ($event: any) => (_ctx.$emit('submit', state.close))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.submitButtonLabel), 1)
              ]),
              _: 2
            }, 1032, ["colors", "onClick"])
          ])
        ])
      ])
    ]),
    _: 3
  }, 8, ["size"]))
}