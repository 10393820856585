
import { defineComponent } from 'vue';
import { createUserWithEmailAndPassword, Unsubscribe } from 'firebase/auth';
import { auth } from '@/plugins/firebase';
import CustomButton from '@/components/ui/CustomButton.vue';
import { ActionType, store } from '@/plugins/store';

export default defineComponent({
    components: { CustomButton },
    data() {
        return {
            form: {
                email: '',
                password: '',
                displayName: '',
            },
            unsubscribeAuthStateListener: null as null | Unsubscribe,
        };
    },
    created() {
        this.unsubscribeAuthStateListener = auth.onAuthStateChanged((user) => {
            if (user) {
                this.$router.push({ name: 'Dashboard' });
            }
        });
    },
    beforeUnmount() {
        if (this.unsubscribeAuthStateListener) {
            this.unsubscribeAuthStateListener();
        }
    },
    methods: {
        async onSubmit() {
            createUserWithEmailAndPassword(auth, this.form.email, this.form.password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    store.dispatch(ActionType.AddFirebaseUser, { form: { email: this.form.email, name: this.form.displayName, uid: user.uid } }).then(() => {
                        this.$router.push({ name: 'Dashboard' });
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
});
