import { createToastInterface } from 'vue-toastification';
import LoadingIndicator from '@/plugins/store/api/LoadingIndicator';
import { options } from '@/plugins/toastification';

let displayErrors = true;
document.addEventListener("visibilitychange", () => { displayErrors = document.visibilityState === 'visible'; });
document.addEventListener("freeze", () => { displayErrors = false; });
document.addEventListener("resume", () => { displayErrors = true; });

export default class ApiRequest {
    private static toast = createToastInterface(options);

    static async send<T>(callback: Promise<any>): Promise<T> {
        LoadingIndicator.start();
        try {
            const result = await callback;
            return result;
        } catch (error: any) {
            if (displayErrors) ApiRequest.toast.error('An unexpected API error has occured');
            throw error;

        } finally {
            LoadingIndicator.done();
        }
    }
}
