import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!

  return (_openBlock(), _createBlock(_component_ConfirmationModal, {
    header: _ctx.$t('deletePlaylist_header'),
    "submit-button-colors": "text-white bg-red-700",
    message: _ctx.modalMessage,
    "submit-button-label": _ctx.$t('deletePlaylist_submit'),
    onSubmit: _ctx.onSubmit
  }, {
    toggle: _withCtx((state) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(state)))
    ]),
    _: 3
  }, 8, ["header", "message", "submit-button-label", "onSubmit"]))
}