
import { defineComponent, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { routes } from '@/plugins/router/routes';
import { auth } from '@/plugins/firebase';
import { store } from '@/plugins/store';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import CustomInput from '@/components/ui/CustomInput.vue';

export default defineComponent({
    components: { CustomInput, CustomSelect },
    data() {
        const roles = computed(() => store.state.user?.roles || []);
        const { t, locale, availableLocales } = useI18n();
        return {
            t,
            locale,
            availableLocales,
            selectedLocale: ref(localStorage.getItem('locale') ?? 'en'),
            sidebarRoutes: computed(() =>
                routes
                    .find((currentRoute) => currentRoute.meta?.isDashboardRoute)!
                    .children!.filter(
                        (currentRoute) =>
                            currentRoute.meta?.appearsInSidebar &&
                            !currentRoute.meta?.requiresUserId &&
                            (currentRoute.meta?.requireRole === undefined || (currentRoute.meta.requireRole as string[]).reduce((allow: boolean, role: string) => allow || roles.value.includes(role), false))
                    )
            ),
            user: computed(() => store.state.user),            
            userRoutes: computed(() =>
                routes
                    .find((currentRoute) => currentRoute.meta?.isDashboardRoute)!
                    .children!.filter(
                        (currentRoute) =>
                            currentRoute.meta?.appearsInSidebar &&
                            currentRoute.meta?.requiresUserId &&
                            (currentRoute.meta?.requireRole === undefined || (currentRoute.meta.requireRole as string[]).reduce((allow: boolean, role: string) => allow || roles.value.includes(role), false))
                    )
            ),
        };
    },
    watch: {
        selectedLocale() {
            this.locale = this.selectedLocale;
            localStorage.setItem('locale', this.selectedLocale);
        },
    },
    methods: {
        async onLogoutClicked() {
            const logout = window.confirm(this.t('components_dashboard_logoutConfirm'));
            if (logout) {
                await auth.signOut();
                this.$router.push({ name: 'Login' });
            }
        },
    },
});
