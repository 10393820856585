import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mt-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardContentHeader = _resolveComponent("DashboardContentHeader")!
  const _component_InfoTable = _resolveComponent("InfoTable")!
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.embeded)
      ? (_openBlock(), _createBlock(_component_DashboardContentHeader, {
          key: 0,
          "icon-source": "/icons/ic_users.svg",
          header: _ctx.customer !== null ? _ctx.customer.name : _ctx.$t('statistics_header')
        }, null, 8, ["header"]))
      : _createCommentVNode("", true),
    _createVNode(_component_InfoTable, { items: _ctx.infoTableItems }, null, 8, ["items"]),
    (_ctx.customer !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Chart, {
            options: _ctx.chartOptions,
            callback: _ctx.chartCallback
          }, null, 8, ["options", "callback"])
        ]))
      : _createCommentVNode("", true)
  ]))
}