import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardContentHeader = _resolveComponent("DashboardContentHeader")!
  const _component_CustomerTabs = _resolveComponent("CustomerTabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DashboardContentHeader, {
      "icon-source": "/icons/ic_users.svg",
      header: _ctx.customer !== null ? _ctx.customer.name : _ctx.$t('Customers_Customer_name')
    }, null, 8, ["header"]),
    (_ctx.customer !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_CustomerTabs, { customer: _ctx.customer }, null, 8, ["customer"])
        ]))
      : _createCommentVNode("", true)
  ]))
}