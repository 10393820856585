import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex h-full flex-col" }
const _hoisted_2 = { class: "text-center pt-2" }
const _hoisted_3 = { class: "flex h-full flex-col my-auto" }
const _hoisted_4 = { class: "grid grid-cols-2 grid-flow-row pt-2 mt-12" }
const _hoisted_5 = { class: "h-6 py-3" }
const _hoisted_6 = { class: "flex mt-10" }
const _hoisted_7 = {
  key: 0,
  class: "h-6 w-6"
}
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_ConfirmDeleteModal = _resolveComponent("ConfirmDeleteModal")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    size: "w-175 h-fit",
    padding: "py-10 px-28"
  }, {
    toggle: _withCtx((state) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(state)))
    ]),
    body: _withCtx((state) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('Customer_Customer_editCustomer_edit')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('Customer_Customer_editCustomer_name')), 1),
            _createVNode(_component_CustomInput, {
              value: _ctx.form.name,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
              placeholder: _ctx.$t('Customer_Customer_editCustomer_name')
            }, null, 8, ["value", "placeholder"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
            return (_openBlock(), _createElementBlock("div", {
              key: user,
              class: "grid grid-cols-2 grid-flow-row pt-2"
            }, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(user.name) + " - " + _toDisplayString(user.role), 1),
              _createVNode(_component_ConfirmDeleteModal, {
                form: { Customer: _ctx.Customer, name: user.displayName, id: user.id },
                "close-parent": state.close
              }, {
                default: _withCtx(({ open_comfirm }) => [
                  _createVNode(_component_CustomButton, {
                    class: "px-2 mt-auto mx-auto w-48",
                    colors: "text-white bg-red-700",
                    onClick: open_comfirm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Customer_Customer_editCustomer_delete')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 2
              }, 1032, ["form", "close-parent"])
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_CustomButton, {
            disabled: _ctx.editingCustomer,
            class: _normalizeClass([{ 'cursor-default': _ctx.editingCustomer }, "px-2 mt-auto mx-auto w-48"]),
            onClick: ($event: any) => (_ctx.onSubmitClicked(state.close))
          }, {
            default: _withCtx(() => [
              (_ctx.editingCustomer)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                    _createVNode(_component_Spinner)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('Customer_Customer_editCustomer_confirm')), 1))
            ]),
            _: 2
          }, 1032, ["disabled", "class", "onClick"])
        ])
      ])
    ]),
    _: 3
  }))
}