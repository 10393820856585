
import { defineComponent, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { Video, Customer } from '@/types';
import { ActionType, store } from '@/plugins/store';
import Modal from '@/components/ui/Modal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';

export default defineComponent({
    components: { CustomButton, Modal },
    props: {
        form: {
            type: Object as () => {
                video: Video;
                name: string;
                id: string;
            },
            required: true,
        },
        customer: {
            type: Object as () => Customer,
            required: true,
        },
    },
    data() {
        const { t } = useI18n();
        return {
            file: ref(null) as any,
            router: useRouter(),
            toast: useToast(),
            modalMessage: t('deleteVideo_confirmMsg', this.form.name),
            t,
        };
    },
    methods: {
        fileChanged(e: any) {
            this.file = (e.target.files || e.dataTransfer.files)?.[0] ?? null;
        },
        async onSubmit(close: CallableFunction): Promise<any> {
            const request = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    AccessKey: this.customer.apiKey,
                    'Content-Type': 'application/*+json',
                },
            };
            const urls = await store.dispatch(ActionType.getSignedURLs);
            const data = new FormData();
            data.append('file', this.file);
            data.append('user', 'hubot');
            const xhr = new XMLHttpRequest(); // Upload file with xhr to avoid odd webkit boundary headers added to binary file upload

            xhr.open('PUT', urls.signedPutUrl);
            xhr.overrideMimeType(this.file.type);
            xhr.setRequestHeader('x-goog-acl', 'public-read');
            xhr.onreadystatechange = async () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const response = await fetch(`https://videocdn.mkbl.dk/library/${this.customer.libId}/videos/${this.form.video.bunnyId}/thumbnail?thumbnailUrl=${urls.signedGetUrl}`, {
                            method: 'POST',
                            headers: { Accept: 'application/json', AccessKey: this.customer.apiKey },
                        });
                        if ((await response.json()).success) {
                            request.method = 'GET';
                            const videoResp = await fetch(`https://videocdn.mkbl.dk/library/${this.customer.libId}/videos/${this.form.video.bunnyId}/`, request);

                            await store.dispatch(ActionType.UpdateThumbnail, { form: { id: this.form.id, customer: this.customer, thumbnailurl: (await videoResp.json()).thumbnailFileName } });
                        }
                        close();
                    }
                }
            };
            xhr.send(this.file);
        },
    },
});
