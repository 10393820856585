
import { defineComponent, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/ui/Modal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { Customer } from '@/types';
import { ActionType, store } from '@/plugins/store';

export default defineComponent({
    emits: [],
    components: { Modal, CustomButton, CustomInput, CustomSelect, Spinner },
    props: {
        customer: {
            type: Object as () => Customer,
            required: true,
        },
    },
    setup() {
        const { t } = useI18n();
        return {
            toast: useToast(),
            addCustomerUser: false,
            email: ref(''),
            role: ref('viewer'),
            t,
        };
    },
    methods: {
        async onSubmitClicked(close: CallableFunction) {
            if (!this.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                this.toast.error(this.t('Customer_Customer_addUser_errorVaildEmail'));
                return;
            }
            try {
                this.addCustomerUser = true;
                
                await store.dispatch(ActionType.addUserToCustomer, { form: { customerId: this.$props.customer.id as string, email: this.email, role: this.role } });
                this.addCustomerUser = false;
                this.toast.success(this.t('Customer_Customer_addUser_success'));
                close();
            } catch (error: any) {
                this.toast.error(this.t('Customer_Customer_addUser_errorGeneral'));
            }
        },
    },
});
